import React from 'react';
import './TermsandConditions.css';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
    const navigate = useNavigate();

    return (
        <div className="terms-container">
            <div className="banner">
                {/*<button className="back-btn" onClick={() => navigate(-1)}>Back</button>*/}
                <div className="title-container">
                    <h1>Terms and Conditions</h1>
                </div>
            </div>
            <div className="terms-content">
                <p>Last Updated: 19th June 2024</p>
                <p>Thanks for considering Artio Carbon Limited’s (hereafter referred to as Artio) economic feasibility report tool (the "Tool"). By accessing or using the Tool, you agree to comply with and be bound by the following terms and conditions (the "Terms"). If you do not agree to these Terms, please do not use the Tool.</p>
                <h3>1. Use of the Tool</h3>
                <p>1.1 Purpose: The Tool is designed to generate preliminary economic feasibility reports for projects at the concept stage, including carbon modelling, IRR & NPV, and scenario analysis.</p>
                <p>1.2 Intended Use: The reports generated by the Tool are for initial comparison of early-stage investment opportunities only. They should not be used as the sole basis for making full investment decisions. Comprehensive feasibility analyses, including detailed due diligence and expert consultations, are essential before any final investment decision is made.</p>
                <p>1.3 Prohibited Use: Users shall not alter, modify, or misuse the reports generated by the Tool for fraudulent purposes, including but not limited to obtaining investment under false pretences.</p>
                <h3>2. Disclaimer</h3>
                <p>2.1 Accuracy: Artio does not guarantee the accuracy or completeness of the data provided by the Tool.</p>
                <p>2.2 Liability: Artio assumes no liability for any decisions made or opportunities lost based on the use of the Tool. Users are advised to seek professional advice and conduct thorough evaluations before proceeding with any investment.</p>
                <p>2.3 Rectification: If a report fails to generate correctly, we will rectify the issue within 48 hours. If the issue cannot be resolved within this timeframe, users are entitled to a refund of any fees paid for that report.</p>
                <h3>3. Feedback and Collaboration</h3>
                <p>3.1 Feedback: Artio values user feedback and is committed to being open and collaborative. Feedback provided by users will be used to improve the Tool.</p>
                <p>3.2 Support: Users can contact Artio for support and inquiries regarding the Tool. We strive to respond to all inquiries promptly and effectively.</p>
                <h3>4. Data Security and Privacy</h3>
                <p>4.1 Data Storage: All data received by Artio through the use of the Tool will be stored securely.</p>
                <p>4.2 Data Deletion: Users may request the deletion of their data at any time by contacting Artio. Upon receiving such a request, we will delete the data promptly.</p>
                <p>4.3 Privacy: Artio is committed to protecting user privacy. Personal information will not be shared with third parties without user consent, except as required by law.</p>
                <h3>5. Intellectual Property</h3>
                <p>5.1 Ownership: The Tool and all content, features, and functionality are and will remain the exclusive property of Artio and its licensors.</p>
                <p>5.2 License: Users are granted a limited, non-exclusive, non-transferable, and revocable license to use the Tool in accordance with these Terms.</p>
                <h3>6. Termination</h3>
                <p>6.1 Breach: Artio reserves the right to terminate or suspend access to the Tool immediately, without prior notice or liability, for any reason, including breach of these Terms.</p>
                <p>6.2 Effect of Termination: Upon termination, the user's right to use the Tool will cease immediately. All provisions of the Terms which by their nature should survive termination shall survive termination.</p>
                <h3>7. Commercial Use and Redistribution</h3>
                <p>The services, tools, and materials provided by Artio Carbon Limited are strictly for personal or internal business use.
                    They may not be used for any commercial purposes, including but not limited to resale, redistribution, sublicensing, or any other form of commercial exploitation without the express written consent of Artio Carbon Limited.
                    Violation of this provision may result in immediate termination of service and may subject the violator to legal action.</p>
                <h3>8. Changes to Terms</h3>
                <p>Artio reserves the right to modify or replace these Terms at any time. Users will be notified of any changes by the posting of the new Terms on the Tool. Continued use of the Tool after any such changes constitutes acceptance of the new Terms.</p>
                <h3>9. Governing Law</h3>
                <p>These Terms shall be governed and construed in accordance with the laws of England & Wales, without regard to its conflict of law provisions.</p>
                <h3>10. Contact Information</h3>
                <p>If you have any questions about these Terms, please contact us at:</p>
                <p>Artio Carbon Limited<br />
                    7 Bell Yard, London, England, WC2A 2JR<br />
                    Email: info@artiocarbon.com</p>
            </div>
        </div>
    );
};

export default TermsAndConditions;
